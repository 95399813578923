<template>
  <div class="py-6">
    <v-container>
      <v-row>
        <v-col cols="12" sm="8">
          <v-row>
          <v-col cols="12" sm="4" v-if="$vuetify.breakpoint.xsOnly">
              <div>
                <donation-info-card></donation-info-card>
              </div>
            </v-col>
            <v-col cols="12">
              <campaign-info></campaign-info>
            </v-col>
           <!-- <v-col cols="12">
              <event-timeline></event-timeline>
            </v-col> -->
          </v-row>
        </v-col>
        <v-col cols="12" sm="4" v-if="$vuetify.breakpoint.smAndUp">
          <div class="fixedCard"><donation-info-card></donation-info-card></div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "PublicDonationInfo",
  components: {
    DonationInfoCard: () => import("./DonationInfoCard/DonationInfoCard"),
    CampaignInfo: () => import("./DonationInfoCard/CampaignInfo"),
    // EventTimeline: () => import("./DonationInfoCard/EventTimeline"),
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
};
</script>
<style scoped>
.fixedCard {
  position: sticky;
  top: 100px;
}
@media only screen and (max-width: 600px){
}
</style>
